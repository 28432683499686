<template>
  <div class="more-results-container" :style="hideDisplay ? 'display: none;' : null">
    <spinner v-if="showSpinner" />
    <b-button-group v-if="showButton">
      <b-dropdown split @click="loadMoreResults(defaultLimit)" class="more-results" size="sm" :disabled="disabled">
        <template #button-content>
          <font-awesome-icon icon="circle-chevron-down" /> More {{ resourceName }}
        </template>
        <b-dropdown-item v-for="limitOption in computedLimitOptions" :key="limitOption.value" @click="loadMoreResults(limitOption.value)">
          {{ limitOption.label }}
        </b-dropdown-item>
      </b-dropdown>
    </b-button-group>
  </div>
</template>
<script>
import Spinner from '@/components/Spinner.vue'

export default {
  components: {
    Spinner
  },
  props: {
    loading: Boolean,
    canLoadMore: Boolean,
    disabled: Boolean,
    defaultLimit: {
      type: Number,
      default: 25
    },
    limitOptions: Array,
    allowAll: Boolean,
    showLoading: {
      type: Boolean,
      default: true
    },
    resourceName: {
      type: String,
      default: "results"
    }
  },
  computed: {
    computedLimitOptions () {
      const limitOptions = this.limitOptions || [25, 50, 100, 200, 400, ...(this.allowAll ? [{ value: 'all', label: 'All results'}] : [])]
      return limitOptions.map(o => ({
        value: o.value || o,
        label: o.label || `${o} more ${this.resourceName}`
      }))
    },
    showSpinner () {
      return this.showLoading && this.loading
    },
    showButton () {
      return this.canLoadMore && !this.loading
    },
    hideDisplay () {
      return !this.showSpinner && !this.showButton
    }
  },
  methods: {
    loadMoreResults (limit) {
      this.$emit('load', limit)
    }
  }
}
</script>
<style lang="scss" scoped>

.more-results-container {
  width: 100%;
  text-align: center;
}

.btn-group {
  display: flex;
  justify-content: center;

  .more-results {
    margin-top: 15px;
    width: 50%;

    :deep(.btn) {
      $more-results-button-radius: 15px;

      &:first-child {
        width: 100%;
        border-top-left-radius: $more-results-button-radius;
        border-bottom-left-radius: $more-results-button-radius;
      }

      &.dropdown-toggle {
        border-top-right-radius: $more-results-button-radius;
        border-bottom-right-radius: $more-results-button-radius;
      }
    }
  }
}
</style>
