import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import { registerPunchCodesWithStore } from '@/services/PunchCodeService'
import store from '@/store'

registerPunchCodesWithStore(store)

registerListResourceWithStore('entryStates', 'entry/states', store, {
  getters: {
    hasActiveEntryStates: state => state.items.some(item => item.active)
  }
})

export default {
  name: 'punch-settings',
  label: 'Punches',
  link: 'punches',
  path: 'punches',
  props: true,
  icon: 'business-time',
  description: 'View and edit your organization\'s punch settings.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_settings',
  require: (state, getters) => getters.hasPunchCodeFeature || getters.hasEntryStateFeature,
  breadcrumbLeaf: true,
  children: [
    {
      name: 'entry-states',
      label: 'Punch States',
      path: 'states',
      component: () => import('./EntryStates.vue'),
      requireFeature: 'entry_state'
    },    {
      name: 'punch-codes',
      label: 'Punch Codes',
      path: 'codes',
      component: () => import('./PunchCodes.vue'),
      requireFeature: 'punch_codes'
    },
    {
      name: 'punch-settings-index',
      path: '',
      redirect: to => ({
        // TODO: This doesn't work on initial load due to settings not loaded yet. Need way to wait.
        name: store.getters.hasEntryStateFeature ? 'entry-states' : 'punch-codes'
      })
    }
  ]
}
