import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import { registerSingletonResourceWithStore } from '@/services/GenericSingletonResourceService'
import store from '@/store'
import _ from 'lodash'
import { updatedDiff } from 'deep-object-diff'
import { payClassesAreIntercompatible } from './util'
import payrollClient from '@/services/clients/payroll'

registerSingletonResourceWithStore('payrollDefaults', 'payroll/defaults', store)
registerListResourceWithStore('netPayAdjustmentCodes', 'netpay/adjustment-codes', store, { restClient: payrollClient })
registerListResourceWithStore('netPayStatutoryDeductions', 'netpay/statutory-deductions', store, { restClient: payrollClient })

// Sync changes from payroll defaults to root store module.
setTimeout(() => {
  store.watch(
    state => ({
      enableNetPay: state.payrollDefaults.originalData.enableNetPay,
      enablePayRun: state.payrollDefaults.originalData.enablePayRun,
      usePayClass: state.payrollDefaults.originalData.usePayClass,
      applyShiftOverlapTo: state.payrollDefaults.originalData.applyShiftOverlapTo,
      payPeriodType: state.payrollDefaults.originalData.payPeriod,
      payPeriodStarts: state.payrollDefaults.originalData.payPeriodStartsOn,
      payrollId: state.payrollDefaults.originalData.payrollId,
    }),
    syncSettings => {
      if (syncSettings.usePayClass === undefined) {
        // This is a litmus test to determine whether Vue is just loading,
        // or whether something actually changed.
        return
      }
      // Get same keys in root state
      const rootSettings = _.pick(store.state, Object.keys(syncSettings))
      const updated = updatedDiff(rootSettings, syncSettings)
      if (!_.isEmpty(updated)) {
        store.dispatch('updateSession', updated)
      }
    }
  )
})

// TODO: Need to merge with payClassMasterDetail module.
registerListResourceWithStore('payClasses', 'payroll/classes', store, {
  getters: {
    // Caller is responsible to dispatch 'payClasses/load'.
    payClassesAreIntercompatible: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.payrollEnabled) return true
      return payClassesAreIntercompatible(state.items, rootState)
    },
    // payClassCompatibilityGroups is useful for splitting up worker timecard requests
    // so that each request is a valid compatibility group.
    payClassCompatibilityGroups: (state, getters, rootState, rootGetters) => {
      if (!rootGetters.payrollEnabled) return {}
      // Each compatibility group is separate array in groups.
      // Payroll default (no class) is identified by null.
      const groups = [[null]]
      for (const payClass of state.items) {
        // Find matching group, or create a new group.
        let found = false
        for (const group of groups) {
          // We need to check it against one pay class in group.
          // So if first item is defaults (null), then check that and a pay class (if any).
          const payrollDefaults = group[0] === null ? rootState : null
          const items = payrollDefaults ? group.slice(1, 2) : group.slice(0, 1)
          if (payClassesAreIntercompatible([payClass, ...items], payrollDefaults)) {
            group.push(payClass)
            found = true
            break
          }
        }
        if (!found) {
          groups.push([payClass])
        }
      }
      // Return mapping of pay class id (or null) to group number (index).
      return Object.fromEntries(groups.flatMap((group, index) => group.map(payClass => [payClass?.id ?? null, index])))
    }
  }
})
registerListResourceWithStore('payCodes', 'payroll/codes', store)

export default {
  name: 'payroll',
  label: 'Payroll',
  link: 'payroll',
  path: 'payroll',
  props: true,
  icon: 'money-bill',
  description: 'View and edit your payroll settings.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_settings',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'payroll-defaults',
      label: 'Defaults',
      title: 'Payroll Defaults',
      path: 'defaults',
      component: () => import('./PayrollDefaultsForm.vue')
    },
    {
      name: 'payclass-list',
      label: 'Pay Classes',
      path: 'classes/:view?',
      props: true,
      requireGetter: 'payrollEnabled',
      component: () => import('./PayClassList.vue')
    },
    {
      name: 'payroll-paycodes',
      label: 'Pay Codes',
      path: 'payCodes',
      requireGetter: 'payrollEnabled',
      component: () => import('./PayCodeList.vue')
    },
    {
      name: 'payroll-rules',
      label: 'Rules',
      title: 'Pay Code Rules',
      path: 'rules',
      requireGetter: 'payrollEnabled',
      component: () => import('./PayCodeRuleList.vue')
    },
    {
      name: 'net-pay-adjustment-codes',
      label: 'Adjustment Codes',
      title: 'Adjustment Codes',
      path: 'netpay/adjustment-codes',
      requireGetter: 'netPayEnabled',
      component: () => import('./NetPayAdjustmentCodeList.vue')
    },
    {
      name: 'net-pay-statutory-deductions',
      label: 'Statutory Deductions',
      title: 'Statutory Deductions',
      path: 'netpay/statutory-deductions/:view?',
      props: true,
      requireGetter: 'netPayEnabled',
      component: () => import('./StatutoryDeductionList.vue')
    },
    {
      name: 'payroll-index',
      path: '',
      redirect: { name: 'payroll-defaults' }
    }
  ]
}
