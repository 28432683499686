import { registerListResourceWithStore } from '@/services/GenericListResourceService'
import { registerSingletonResourceWithStore } from '@/services/GenericSingletonResourceService'
import store from '@/store'
import _ from 'lodash'
import { updatedDiff } from 'deep-object-diff'
import { hasValue } from '@/utils/misc'

registerSingletonResourceWithStore('shiftDefaults', 'shift/defaults', store)
registerListResourceWithStore('roundingRules', 'shift/roundingrules', store)
// TODO: Need to merge with shiftBreakMasterDetail module.
registerListResourceWithStore('shiftBreaks', 'shift/breaks', store)
// TODO: Need to merge with shiftClassMasterDetail module.
registerListResourceWithStore('shiftClasses', 'shift/classes', store)

setTimeout(() => {
  // Sync changes from shift defaults to root store module.
  store.watch(
    state => ({
      shiftMode: state.shiftDefaults.originalData.shiftMode,
      openShiftType: state.shiftDefaults.originalData.workShift,
      // When the shift defaults form is mounted, there will be an update to openShiftStartsAt,
      // because the component strips off the seconds. Although it would seem unexpected,
      // it's tolerable.
      openShiftStartsAt: state.shiftDefaults.originalData.shiftStartsAt,
      // convert hours to minutes
      openShiftMaxDuration: (state.shiftDefaults.originalData.maxShift || 0) * 60,
      maxExtendShiftEarly: state.shiftDefaults.originalData.maxExtendShiftEarly,
      maxExtendShiftLate: state.shiftDefaults.originalData.maxExtendShiftLate
    }),
    syncSettings => {
      // Get same keys in root state
      const rootSettings = _.pick(store.state, Object.keys(syncSettings))
      const updated = updatedDiff(rootSettings, syncSettings)
      const nonEmptyUpdated = _.pickBy(updated, (value, key) => hasValue(rootSettings[key]) || hasValue(syncSettings[key]))
      // TODO: Why don't the other route sync settings have this issue?
      if ('shiftMode' in updated && updated.shiftMode === undefined) return // ignore noise at load time
      if (!_.isEmpty(nonEmptyUpdated)) {
        store.dispatch('updateSession', nonEmptyUpdated)
      }
    }
  )
})

export default {
  name: 'shifts',
  label: 'Shifts',
  link: 'shifts',
  path: 'shifts',
  props: true,
  icon: 'calendar-check',
  description: 'View and edit your shift rules.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'edit_settings',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'shift-defaults',
      label: 'Defaults',
      title: 'Shift Defaults',
      path: 'defaults',
      component: () => import('./ShiftDefaultsForm.vue')
    },
    {
      name: 'shift-shiftclasses',
      label: 'Shift Classes',
      path: 'classes/:view?',
      props: true,
      requireGetter: 'scheduleEnabled',
      component: () => import('./ShiftClassList.vue')
    },
    {
      name: 'shift-breaks',
      label: 'Breaks',
      path: 'breaks/:view?',
      props: true,
      requireGetter: 'scheduleEnabled',
      component: () => import('./BreakList.vue')
    },
    {
      name: 'shift-roundingrules',
      label: 'Rounding Rules',
      path: 'roundingRules',
      requireFeature: 'schedule',
      component: () => import('./RoundingRuleList.vue')
    },
    {
      name: 'shifts-index',
      path: '',
      redirect: { name: 'shift-defaults' }
    }
  ]
}
