<template>
  <div class="costing-selection">
    <form-group
      :validator="v$.value[jobLabelAttribute]"
      v-if="jobLabelEnabled"
      label="Job label"
      class="job-label"
      v-bind="formGroupConfig"
    >
      <template #default="slotProps">
        <label-select
          v-bind="slotProps"
          v-model="value[jobLabelAttribute]"
          :multiple="false"
          labelType="work"
          :disabled="disabled"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[jobAttribute]"
      label="Job"
      class="job"
      v-bind="formGroupConfig"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="jobRef"
          v-model="value[jobAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :serviceFetch="fetchJobs"
          :disabled="disabled"
          @fullValueChange="state.job = $event"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[jobPhaseAttribute]"
      label="Job phase"
      class="job-phase"
      v-bind="formGroupConfig"
      v-if="jobPhaseEnabled"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="jobPhaseRef"
          v-model="value[jobPhaseAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :serviceFetch="fetchJobPhases"
          :disabled="disabled"
          @fullValueChange="state.jobPhase = $event"
        />
      </template>
    </form-group>
    <form-group
      :validator="v$.value[costCodeAttribute]"
      label="Cost code"
      class="cost-code"
      v-bind="formGroupConfig"
      v-if="costCodeEnabled"
    >
      <template #default="slotProps">
        <remote-multiselect
          v-bind="slotProps"
          ref="costCodeRef"
          v-model="value[costCodeAttribute]"
          :id="uniqueIdentifier"
          label="name"
          track-by="id"
          select-label=""
          deselect-label=""
          :disabled="disabled"
          :serviceFetch="fetchCostCodes"
          @fullValueChange="state.costCode = $event"
        />
      </template>
    </form-group>
    <form-group v-if="enablePartialCosting">
      <b-form-checkbox v-model="value[costingIsPartialAttribute]" :disabled="disabled">
        Costing Selection is Partial
        <help-text-icon>
          If checked, then the worker will be prompted on the time clock to select
          any fields which were not locked here, as long as that field has values to select.
          <template v-if="showMinVersion">
            <br><br>
            This feature requires time clock mobile app version 2.11 or later for job phase and cost code partial lock.
            The job label lock requires version 3.1 or later.
          </template>
        </help-text-icon>
      </b-form-checkbox>
    </form-group>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import LabelSelect from '@/views/settings/organization/LabelSelect.vue'
import RemoteMultiselect from '@/components/RemoteMultiselect.vue'
import HelpTextIcon from '@/components/HelpTextIcon.vue'
import { forceArray } from '@/utils/misc'
import { CostingSelectionProps, CostingSelectionEmits, useCostingSelection } from '@/composables/costingSelection'

const jobRef = ref(null)
const jobPhaseRef = ref(null)
const costCodeRef = ref(null)

const props = defineProps({
  ...CostingSelectionProps,
  formGroupConfig: Object,
  disabled: {
    type: Boolean,
    default: false
  },
  showMinVersion: {
    type: Boolean,
    default: true
  }
})
const emit = defineEmits(CostingSelectionEmits)

const {
  state,
  v$,
  jobLabelEnabled,
  jobPhaseEnabled,
  costCodeEnabled,
  fetchJobs,
  fetchJobPhases,
  fetchCostCodes
} = useCostingSelection({
  props,
  emit,
  onParentFilterChanged: () => {
    // refetch jobs since filter changed
    jobRef.value?.trySearch?.()
    jobPhaseRef.value?.trySearch?.()
    costCodeRef.value?.trySearch?.()
  }
})

// TODO: Do job phase and cost code fields need a different identifier that includes job id?
const uniqueIdentifier = computed(() => `${props.value.id}-${props.value.employee}-${forceArray(props.deviceOrgUnit || []).join('-')}`)

</script>

<style lang="scss" scoped>
.costing-selection {
  .form-group {
    margin: .5rem;
  }
  .multiselect {
    max-width: 20rem;
  }
}
</style>
