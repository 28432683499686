<template>
  <multiselect
    ref="select"
    v-bind="$attrs"
    :modelValue="completeValue"
    :options="options"
    :track-by="trackBy"
    :taggable="taggable"
    @update:modelValue="onChange"
    @tag="addTag"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
    :class="['key-multiselect', 'multiselect--clearable', `multiselect-${size}`]"
  >
    <template #beforeList>
      <template v-if="showSelectAll || showSelectNone">
        <li class="select-all-or-none">
          <b-button variant="link" size="sm" style="padding:0" v-if="showSelectAll" @click="selectAll">Select All</b-button>
          <b-button variant="link" size="sm" style="padding:0" v-if="showSelectNone" @click="selectNone">Select None</b-button>
        </li>
      </template>
      <slot name="beforeList" />
    </template>

    <template #afterList>
      <template v-if="showAddNewItem">
        <hr class="after-list-divider" />
        <li class="add-new-item multiselect__element">
          <span class="multiselect__option" @click.stop="$emit('add-new-item')">
            &lt; Add a new {{ resourceName }} &gt;
          </span>
        </li>
      </template>
      <slot name="afterList" />
    </template>

    <!-- TODO: Do we need to iterate a copy of $slots without beforeList, afterList, and clear? -->
    <template v-for="(_, name) in passThruSlots" #[name]="slotData">
      <slot :name="name" v-bind="slotData" />
    </template>

    <template #clear>
      <font-awesome-icon
        v-if="modelValue && !$attrs.multiple && $attrs.allowEmpty !== false && !$attrs.disabled"
        class="multiselect__clear"
        icon="xmark"
        @mousedown.prevent="clearValue"
      />
    </template>
  </multiselect>
</template>
<script>
import Multiselect from 'vue-multiselect'
import { forceArray, hasValue } from '@/utils/misc'
import _ from 'lodash'

// See discussion on this issue:
// https://github.com/shentao/vue-multiselect/issues/385
export default {
  name: 'KeyMultiselect',
  inheritAttrs: false,
  components: {
    Multiselect
  },
  props: {
    // Originally, Boolean was the first allowed type in the following array.
    // But something strange happened: One taggable instances, an empty string value was
    // defaulting to boolean true. We saw this on EmployeeForm RelationshipSelector
    // component. Moving Boolean type to end resolves the issue.
    modelValue: [Number, String, Array, Boolean],
    options: Array,
    trackBy: String,
    taggable: {
      type: Boolean,
      default: false
    },
    resourceName: String,
    showAddNewItem: Boolean,
    showSelectAllOrNone: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'normal',
      validator: value => ['compact', 'normal'].includes(value)
    }
  },
  emits: ['add-new-item', 'fullValueChange', 'update:modelValue', 'blur', 'focus'],
  computed: {
    completeValue: {
      get () {
        if (!hasValue(this.modelValue)) return null
        if (this.$attrs['multiple']) {
          // TODO: handle value not found if taggable
          return forceArray(this.modelValue).map(value => this.findOption(value)).filter(value => value)
        } else {
          // TODO: Value not getting cleared if not found in options.
          const completeValue = this.findOption(this.modelValue)
          if (completeValue === undefined && this.taggable) {
            this.addTag(this.modelValue)
          }
          return completeValue
        }
      },
      set (v) {
        this.$emit('update:modelValue', this.$attrs['multiple']
          ? v.map(value => value[this.trackBy])
          : (v && v[this.trackBy])
        )
      }
    },
    showSelectAll () {
      return Boolean(this.showSelectAllOrNone && this.$attrs['multiple'] && this.options.length > 1 && this.modelValue.length < this.options.length)
    },
    showSelectNone () {
      return Boolean(this.showSelectAllOrNone && this.$attrs['multiple'] && this.options.length > 1 && this.modelValue.length > 0)
    },
    passThruSlots () {
      return Object.fromEntries(Object.entries(this.$slots)
        .filter(([k, v]) => !['beforeList', 'afterList', 'clear'].includes(k))
      )
    }
  },
  watch: {
    completeValue: {
      handler (value, oldValue) {
        const newList = forceArray(value)
        const oldList = forceArray(oldValue)

        const newTrackBy = newList.map(v => _.get(v, this.trackBy))
        const oldTrackBy = oldList.map(v => _.get(v, this.trackBy))

        if (!_.isEqual(_.sortBy(newTrackBy), _.sortBy(oldTrackBy))) {
          this.$emit('fullValueChange', value)
        }
      },
      // Use immediate watcher so that we emit initial full value,
      // in case parent needs to display values,
      // e.g., print report filters as subtitles.
      immediate: true
    }
  },
  methods: {
    onChange (value) {
      this.completeValue = value
    },
    findOption (value) {
      return this.options.find(option => option[this.trackBy] === value)
    },
    addTag (value) {
      const newOption = {
        [this.trackBy]: value,
        [this.$attrs.label]: value
      }
      this.options.push(newOption)
      // TODO: if multiple then push
      this.completeValue = newOption
    },
    clearValue () {
      this.onChange(null)
    },
    selectAll () {
      this.onChange(this.options)
    },
    selectNone () {
      this.onChange([])
    }
  },
  mounted () {
    const selectEl = this.$refs.select.$el
    if (selectEl) {
      const inputEl = selectEl.querySelector('input')
      if (inputEl) {
        inputEl.addEventListener('focus', event => this.$emit('focus', event))
        inputEl.addEventListener('blur', event => this.$emit('blur', event))
      }
    }
  }
}
</script>

<style lang="scss">
@import 'vue-multiselect/dist/vue-multiselect.css';
</style>

<style lang="scss" scoped>
@use '@/assets/scss/variables';

.key-multiselect {
  &.multiselect--active {
    // Use css variable to make it easier to override.
    --multiselect-active-z-index: 50;
    z-index: var(--multiselect-active-z-index);
  }
  .multiselect__clear{
    display: none;
  }
  &.multiselect--clearable > .multiselect__clear{
    display: block;
    position: absolute;
    right: 40px;
    top: 13px;
    height: 14px;
    width: 14px;
    /* display: block; */
    cursor: pointer;
    z-index: 3;
    border: none;
    background: #fff;
    color: #999;

    &::before, &::after {
      content: "";
      background: #999999;
      top: 2px;
      left: 10px;
      display: block;
      cursor: pointer;
      z-index: 3;
      position: absolute;
      width: 3px;
      height: 16px;
      right: 4px;
    }
    &::before {
      transform: rotate(45deg)
    }
    &::after {
      transform: rotate(-45deg)
    }
  }

  &.multiselect-compact {
    :deep() {
      min-width: 8rem;
      max-width: 10rem;
      min-height: inherit;

      .multiselect__single, .multiselect__input, .multiselect__option {
        font-size: 13px;
      }
      .multiselect__single {
        margin-bottom: 3px;
      }

      .multiselect__tags {
        padding: 3px 40px 0 3px;
        min-height: 30px;
        font-size: 12px;

        .multiselect__input {
          margin-bottom: 0;
        }
        .multiselect__spinner {
          width: 30px;
          height: 28px;
          &::before, &::after {
            width: 13px;
            height: 13px;
          }
        }
        .multiselect__placeholder {
          margin-bottom: 0;
          padding-left: 3px;
        }
        .multiselect__tag {
          margin-bottom: 2px;
          &:first-child {
            margin-top: 2px;
          }
        }
      }
      .multiselect__element {
        height: 30px;

        .multiselect__option {
          padding: 6px;
          min-height: 30px;
          &::after {
            line-height: 30px;
            font-size: 11px;
          }
        }
      }
      .multiselect__clear {
        top: 9px;
        right: 30px;
        height: 12px;
        width: 12px;
      }
      .multiselect__select {
        width: 30px;
        height: 28px;
        &::before {
          top: 75%;
        }
      }
    }
  }

  :deep() {
    .multiselect__option, .multiselect__single {
      max-width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    // Default placeholder styling takes more height than single selected value.
    // Let's make them the same height and padding.
    .multiselect__placeholder {
      line-height: 20px;
      padding-top: 0px;
      margin-bottom: 8px;
    }

    .select-all-or-none {
      display: flex;
      flex-direction: row;
      padding: 5px 12px;
      // prevent remote multiselect li line-height override for this particular element
      line-height: 1.5 !important;
      gap: 15px;
      a {
        margin-right: 1.5rem;
        font-size: 14px;
      }
    }

    .after-list-divider {
      position: sticky;
      bottom: 40px;
      background-color: white;
      margin-bottom: 0;
    }

    .add-new-item {
      position: sticky;
      bottom: 0;
      background-color: white;

      &:hover {
        background-color: variables.$fc-logo-secondary-blue;
        color: #fff;
      }
    }
  }
}
</style>
