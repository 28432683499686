import { nextTick } from 'vue'
import { createRouter, createWebHistory } from 'vue-router'
import nav from '../OrgNav'
import store from '@/store'

import Full from '@/components/Full.vue'
import RouterPassThrough from '@/components/RouterPassThrough.vue'
import NotificationMessageList from '@/views/notification/NotificationMessageList.vue'
import sudoRoutes from '@/views/sudo/routes'

const viewRoutes = nav.routeItems

const routes = [
  {
    path: '',
    name: 'home',
    component: Full,
    meta: {
      label: 'Home',
      icon: 'home',
      requireActive: false
    },
    children: [
      {
        path: 'error',
        name: 'error',
        component: () => import('@/views/FatalError.vue'),
        meta: {
          label: 'Error',
          icon: 'triangle-exclamation',
          requireActive: false
        }
      },
      {
        path: 'orgs/sign-up',
        name: 'sign-up-wizard',
        component: () => import('@/views/settings/billing/SignUpWizard.vue'),
        meta: {
          label: 'Sign Up Wizard',
          icon: 'magic',
          requireActive: false,
          require: (state, getters) => !getters['userProfile/isWhiteLabelNotAdmin']
        }
      },
      {
        path: 'orgs',
        name: 'org-selector',
        component: () => import('@/views/OrgSelector.vue'),
        meta: {
          label: 'My Organizations',
          icon: 'sitemap',
          requireActive: false
        }
      },
      {
        path: 'orgs/:orgId(\\d+)',
        // console will log warning about naming this route, because it has a default child route.
        // but our navigation system (sidebar, breadcrumbs, table of contents) depends on having a name.
        // so leave it be.
        name: 'org-namespace',
        // Create new component from RouterPassThrough with distinct name,
        // since Vue keep-alive include semantics are based on component names.
        // We're doing all this for the Dashboard, because its state is expensive to compute.
        // TODO: We should probably store dashboard state in vuex instead.
        component: Object.assign({}, RouterPassThrough, { name: 'OrgNamespace' }),
        meta: {
          icon: 'home',
          requireActive: false,
          keepAlive: true
        },
        children: viewRoutes.concat([
          {
            path: 'sign-up',
            name: 'inapp-sign-up-wizard',
            component: () => import('@/views/settings/billing/SignUpWizard.vue'),
            meta: {
              label: 'Sign Up Wizard',
              icon: 'magic',
              requireActive: false,
              requireGetter: 'needsSetup',
              require: (state, getters) => !getters['userProfile/isWhiteLabelNotAdmin']
            }
          },
          {
            path: 'accept',
            name: 'accept',
            component: () => import('@/views/AcceptOrg.vue'),
            meta: {
              label: 'Accept',
              icon: 'circle-question',
              requireActive: false
            }
          },
          {
            path: 'auth-policy-error',
            name: 'auth-policy-error',
            component: () => import('@/views/AuthPolicyError.vue'),
            meta: {
              label: 'Auth Policy Error',
              icon: 'triangle-exclamation',
              requireActive: false
            }
          },
          {
            path: 'notifications',
            name: 'org-notification-messages',
            meta: {
              label: 'Notifications',
              icon: 'bell'
            },
            props: true,
            component: NotificationMessageList,
          },
          { name: 'org-namespace-index', path: '', redirect: { name: 'dashboard' } },
          { name: 'org-namespace-wildcard', path: '*', redirect: { name: 'dashboard' } }
        ])
      },
      {
        path: 'notifications',
        name: 'notification-messages',
        meta: {
          label: 'Notifications',
          icon: 'bell'
        },
        component: NotificationMessageList,
      },
      {
        path: 'upgrade',
        name: 'upgrade-promo',
        component: () => import('@/views/settings/billing/UpgradePromo.vue'),
        meta: {
          label: 'Upgrade',
          icon: 'fab fa-black-tie'
        }
      },
      sudoRoutes
    ]
  },
  { path: '/:pathMatch(.*)*', redirect: '' }
]

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes
})

// https://stackoverflow.com/questions/51639850/how-to-change-page-titles-when-using-vue-router
const DEFAULT_TITLE = 'Fareclock Console'
router.afterEach((to, from) => {

  // https://github.com/vuejs/vuex-router-sync/blob/master/src/index.ts
  store.commit('setRoute', {
    name: to.name,
    path: to.path,
    hash: to.hash,
    query: to.query,
    params: to.params,
    fullPath: to.fullPath,
    meta: to.meta
  })

  // Use next tick to handle router history correctly
  // see: https://github.com/vuejs/vue-router/issues/914#issuecomment-384477609
  nextTick(() => {
    const title = to.meta.title || to.meta.label
    document.title = title ? `${title} - ${DEFAULT_TITLE}` : DEFAULT_TITLE
  })
})

export default router
