export default {
  name: 'manage-timeoff',
  label: 'Time Off',
  link: 'timeoff',
  path: 'timeoff',
  props: true,
  icon: 'umbrella-beach',
  description: 'View and edit worker time off entries and requests.',
  component: () => import('@/components/TabPanel.vue'),
  requirePerm: 'manage_pto',
  requireFeature: 'pto',
  requireGetter: 'timeOffEnabled',
  breadcrumbLeaf: true,
  children: [
    {
      name: 'timeoff-entries',
      label: 'Entries',
      title: 'Time Off Entries',
      path: 'entries/:view?',
      component: () => import('./TimeOffEntries.vue')
    },
    {
      name: 'timeoff-requests',
      label: 'Requests',
      title: 'Time Off Requests',
      path: 'requests/:view?',
      component: () => import('./TimeOffRequests.vue')
    },
    {
      name: 'manage-timeoff-index',
      path: '',
      redirect: { name: 'timeoff-entries' }
    }
  ]
}
