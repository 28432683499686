import type { ReadonlyDeep } from 'type-fest'

// https://learntypescript.dev/10/l5-deep-immutable#creating-a-deepfreeze-function
export function deepFreeze<T>(obj: T): ReadonlyDeep<T> {
  const propNames = Object.getOwnPropertyNames(obj)
  for (let name of propNames) {
    let value = (obj as any)[name]
    if (value && typeof value === "object") {
      deepFreeze(value)
    }
  }
  return Object.freeze(obj) as ReadonlyDeep<T>
}
