<template>
  <b-modal
    v-bind="$attrs"
    modal-class='detail-form-modal'
    :dialog-class="['detail-form-modal-dialog', 'printable', dialogClass]"
    visible
    no-close-on-backdrop
    no-close-on-esc
    no-trap
    no-fade
    no-footer
    centered
    size="lg"
  >
    <template #title>
      <div class="modal-title">
        {{ modalTitle }}
        <id-icon :id="itemId" v-if="itemId" />
        <audit-icon
          v-if="showAudit"
          :instanceId="originalData.id"
          :kind="auditResourceName"
          :resourceName="resourceName"
          @link-opened="close()"
        />
        <font-awesome-icon-with-title icon="print" title="Print" @click="print" class="print-button not-printable" />
      </div>
    </template>
    <div class="load-error" v-if="loadError">
      <font-awesome-icon icon="triangle-exclamation" />
      {{ loadError }}
    </div>
    <div class="loading" v-else-if="loading">
      <font-awesome-icon icon="circle-notch" spin />
      Loading...
    </div>
    <div v-else>
      <slot
        :originalData="originalData"
        :supplementalData="supplementalData"
        :formData="formData"
        :formDirty="formDirty"
        :isFormOpen="true"
        :eventBus="eventBus"
        :detailUpdated="detailUpdated"
        :detailIndex="0"
        :formDataChanged="formDataChanged"
        :formInvalidChanged="formInvalidChanged"
        :applyGridTransaction="applyGridTransaction"
        :exit="close"
        :addingNew="addingNew"
        :saving="saving"
        :readOnly="readOnly"
      />
      <hr/>
      <div class="footer-buttons">
        <!-- TODO: Add spinner when saving -->
        <b-button
          @click="save"
          :disabled="saveDisabled"
          variant="primary"
          class="action save"
          size="sm"
        >
          <font-awesome-icon :icon="saving ? 'circle-notch' : 'circle-check'" :spin="saving" />
          Save
        </b-button>

        <b-button
          @click="save('close')"
          :disabled="saveDisabled"
          variant="primary"
          class="action save"
          size="sm"
        >
          <font-awesome-icon :icon="saving ? 'circle-notch' : 'circle-check'" :spin="saving" />
          Save &amp; Close
        </b-button>

        <b-button
          @click="cancel"
          :disabled="cancelDisabled"
          variant="secondary"
          class="action cancel"
          size="sm"
        >
          <font-awesome-icon icon="circle-xmark" />
          {{ formDirty ? 'Cancel' : 'Close' }}
        </b-button>

        <b-button
          v-if="isDeleteEnabled && !addingNew"
          @click="deleteItem"
          :disabled="deleteDisabled"
          variant="danger"
          class="action delete"
          size="sm"
        >
          <font-awesome-icon icon="trash-can" />
          Delete
        </b-button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import mitt from '@/utils/mitt'
import { extractErrorMessage } from '@/utils/misc'
import _ from 'lodash'
import AuditIcon from '@/components/AuditIcon.vue'
import FontAwesomeIconWithTitle from '@/components/FontAwesomeIconWithTitle.vue'
import IdIcon from '@/components/IdIcon.vue'
import { mapGetters } from 'vuex'
import { printWindow } from '@/utils/print'
import { useModalController } from 'bootstrap-vue-next'

export default {
  name: 'DetailFormModal',
  setup () {
    return {
      hideModal: useModalController().hide
    }
  },
  inheritAttrs: false,
  components: {
    AuditIcon,
    FontAwesomeIconWithTitle,
    IdIcon
  },
  props: {
    resourceName: String,
    auditResourceName: String,
    crudService: Object,
    itemId: Number,
    deleteEnabled: [Boolean, Function],
    dialogClass: String,
    readOnly: Boolean
  },
  emits: ['apply-grid-transaction', 'deleted', 'saved'],
  data () {
    return {
      loadError: null,
      saving: false,
      originalData: {},
      supplementalData: {},
      formData: {},
      formInvalid: false,
      eventBus: mitt()
    }
  },
  computed: {
    ...mapGetters(['canViewAudit']),
    loading () {
      return !this.addingNew && _.isEmpty(this.originalData)
    },
    modalTitle () {
      // TODO: Need prop or slot to customize name for editing specific instance.
      return this.itemId ? `Edit ${this.resourceName}` : `Add New ${this.resourceName}`
    },
    addingNew () {
      return !this.itemId
    },
    showAudit () {
      return Boolean(this.auditResourceName && !this.addingNew && this.canViewAudit)
    },
    saveDisabled () {
      return (!this.addingNew && !this.formDirty) || this.formInvalid || this.saving || this.readOnly
    },
    cancelDisabled () {
      return this.saving
    },
    deleteDisabled () {
      return this.saving || this.readOnly
    },
    formDirty () {
      return !_.isEqual(this.originalData, this.formData)
    },
    isDeleteEnabled () {
      if (typeof(this.deleteEnabled) === 'function') {
        return this.deleteEnabled(this.originalData)
      }
      return !!this.deleteEnabled
    }
  },
  methods: {
    close () {
      this.hideModal()
    },
    // detailUpdated is not used currently in this modal use-case.
    // It's only use case is for MasterDetail punch, where the form loads extra details.
    detailUpdated (originalData, supplementalData) {
      this.originalData = originalData
      this.supplementalData = supplementalData
    },
    formDataChanged (formData) {
      this.formData = formData
    },
    formInvalidChanged (formInvalid) {
      this.formInvalid = formInvalid
    },
    getResultItem (data) {
      // The newer backend will return a `result` object,
      // whereas the older backend will return a `results`
      // object with a single item.
      return data.result || data.results?.[0] || data
    },
    save (afterSaveAction) {
      if (this.saving) return
      this.saving = true
      this.crudService[this.addingNew ? 'create' : 'update'](this.formData)
        .then(data => {
          const item = this.getResultItem(data)
          this.originalData = item
          this.supplementalData = data.supplementalData
          this.$emit('saved', item)
          if (afterSaveAction === 'close') this.close()
        })
        .catch(error => this.$toast.error(extractErrorMessage(error)))
        .finally(() => this.saving = false)
    },
    cancel () {
      if (!this.addingNew && this.formDirty) {
        this.formData = _.cloneDeep(this.originalData)
      } else {
        this.close()
      }
    },
    deleteItem () {
      if (this.saving) return
      this.saving = true
      this.crudService.delete(this.itemId)
        .then(() => {
          this.$emit('deleted', this.itemId)
          this.close()
        })
        .catch(error => this.$toast.error(extractErrorMessage(error)))
        .finally(() => this.saving = false)
    },
    applyGridTransaction (params) {
      this.$emit('apply-grid-transaction', params)
    },
    print () {
      // TODO: This doesn't work when the modal is over another printable screen such as a detail form,
      // TODO: e.g., GeoRuleFormModal over JobForm. We should be able to make printWindow accept a more
      // TODO: specific selector.
      printWindow(window)
    }
  },
  created () {
    if (this.itemId) {
      // We only set originalData object. The specific detail form component should have a watcher
      // on its originalData prop, and set form data appropriately.
      this.crudService.get(this.itemId)
        .then(data => {
          const item = this.getResultItem(data)
          this.originalData = item
          this.supplementalData = data.supplementalData
        })
        .catch(error => this.loadError = extractErrorMessage(error))
    } else {
      // The detail form component should detect that originalData is an empty object, and call
      // a new item factory function.
      this.originalData = {}
      this.supplementalData = {}
    }

    this.eventBus.on('deleteItem', this.deleteItem)
  },
  beforeDestroy () {
    this.eventBus.off('deleteItem', this.deleteItem)
  }
}
</script>
<style lang="scss">
@use '@/assets/scss/variables';
@import '@/assets/scss/app/print';

.detail-form-modal-dialog {
  max-width: calc(100% - 6rem);
  @media (min-width: 1600px) {
    max-width: 1500px;
  }

  .modal-title {
    // Undo modal header font size enlargment.
    font-size: 1rem;
  }

  .audit-icon {
    margin-left: .5rem;
  }

  .print-button {
    margin: 2px .5rem 0 .75rem;
    cursor: pointer;
  }

  .footer-buttons {
    margin-top: 15px;
    display: flex;

    .action {
      margin-right: 10px;
      margin-bottom: 15px;

      &.delete {
        margin-left: auto;
      }
    }
  }

  .loading {
    color: variables.$flat-ui-peter-river;
    text-align: center;
  }
  .load-error {
    color: variables.$flat-ui-alizarin;
    text-align: center;
  }

}
</style>
