<template>
<face-failure-content v-bind="$attrs"
  @approve="approve"
  @reject="reject"
  approveText="Yes"
  rejectText="No"
  :faceLogId="faceLogId"
  :approveDisabled="!decisionAllowed"
  :rejectDisabled="!decisionAllowed"
  >
  <template #employeeQuestion>
    <span class="question">
      Is this
      <template v-if="employeeAvatar">
        <b-button variant="link" size="sm" style="padding:0" id="avatarLink" class="avatar-link" key="employee-name">{{ employeeName }}</b-button>
        <b-popover
          ref="popover"
          target="avatarLink"
          @hidden="popoverVisible = false"
          @shown="popoverVisible = true"
        >
          <avatar :url="employeeAvatar" size="100px" />
        </b-popover>
      </template>
      <template v-else>
        <span key="employee-name">{{ employeeName }}</span>
      </template>
      ?
    </span>
  </template>
</face-failure-content>
</template>
<script>
import Avatar from '@/components/Avatar.vue'
import FaceFailureContent from './FaceFailureContent.vue'
import { mapGetters, mapState } from 'vuex'

export default {
  inheritAttrs: false,
  components: {
    Avatar,
    FaceFailureContent
  },
  props: {
    faceLogId: Number,
    employee: Number,
    employeeFirstName: String,
    employeeMiddleName: String,
    employeeLastName: String,
    employeeDisplayName: String,
    employeeAvatar: String
  },
  emits: ['approve', 'reject'],
  data () {
    return {
      popoverVisible: false
    }
  },
  computed: {
    ...mapState(['orgUserId']),
    ...mapGetters(['canEditSelf']),
    ...mapGetters('formatPreferences', ['formatName']),
    employeeName () {
      return this.formatName(this.employeeFirstName, this.employeeLastName, this.employeeMiddleName, this.employeeDisplayName)
    },
    decisionAllowed () {
      return this.canEditSelf || this.orgUserId !== this.employee
    }
  },
  watch: {
    faceLogId (faceLogId) {
      // Refresh popover so that it re-renders next to right side of name.
      if (this.popoverVisible) {
        setTimeout(() => {
          if (faceLogId === this.faceLogId && this.$refs.popover) {
            this.$refs.popover.updateContent?.()
          }
        }, 250)
      }
    }
  },
  methods: {
    approve () {
      this.$emit('approve', {
        id: this.employee,
        name: this.employeeName
      })
    },
    reject () {
      this.$emit('reject')
    }
  }
}
</script>
<style lang="scss" scoped>

.question {
  text-align: left;
}

.avatar-link {
  text-decoration: underline;
  text-decoration-style: dotted;
  -webkit-text-decoration-style: wavy; // dotted doesn't work in safari
}

</style>
